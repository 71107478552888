<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <div class="add-form-inner">
        <div class="form-group-wrapper-item title">
          <h1>{{ $t("SPOT_TYPES.SPOT_TYPE_INFORMATION") }}</h1>
        </div>

        <div class="form-group-wrapper">
          <!-- name -->
          <div class="form-group-wrapper-item full">
            <base-input
              :label="`${$t('COMMON.NAME')} (*)`"
              v-model="spotType.name"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.name" />
          </div>

          <!-- Category -->
          <div class="form-group-wrapper-item full">
            <base-input :label="`${$t(`COMMON.CATEGORY`)} (*)`">
              <spot-category-selector
                :spotCategory="spotType.category?.id"
                :filterable="true"
                :showAll="false"
                :filterOrganization="spotType.organization?.id"
                @spotCategoryChanged="
                  (spotCategoryId) => {
                    spotType.category.id = spotCategoryId;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.category" />
          </div>

          <!-- Excerpt -->
          <div class="form-group-wrapper-item full">
            <base-input
              :label="$t('COMMON.COMMENT')"
              :placeholder="$t('COMMON.COMMENT')"
            >
              <html-editor v-model="spotType.excerpt" @change="onFormChanged()">
              </html-editor>
            </base-input>
            <validation-error :errors="apiValidationErrors.excerpt" />
          </div>
        </div>
      </div>
      <div class="submit-wrapper">
        <base-button
          class="btn cancel"
          type="button"
          @click="onCloseSpotTypeModal"
        >
          {{ $t("COMMON.CANCEL") }}
        </base-button>
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          :disabled="loading || processing"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
          {{ spotType.id ? $t("COMMON.EDIT") : $t("COMMON.ADD_ITEM") }}
        </base-button>
      </div>
    </form>
    <modal
      :show="showSpotTypeCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("SPOT_TYPES.SAME_SPOT_TYPES", {
                name: spotType.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="spotTypes"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column min-width="50">
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip
                    :content="$t('COMMON.VIEW')"
                    placement="top"
                    v-if="$currentUserCan($permissions.PERM_VIEW_SPOT_TYPES)"
                  >
                    <a
                      type="text"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <router-link :to="$objectViewRoute(row)">
                        <i class="fal fa-expand-alt"></i>
                      </router-link>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleSpotTypeCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Option,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import SpotCategorySelector from "@/components/SpotCategorySelector.vue";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    SpotCategorySelector,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
  },

  mixins: [formMixin],

  props: ["spotTypeData", "formErrors", "loading", "hideSubmit"],

  data() {
    let spotTypeData = { ...this.spotTypeData };
    return {
      spotType: spotTypeData,
      spotTypes: [],
      total: 0,
      showSpotTypeCheckModal: false,
      processing: false,
    };
  },

  created() {},

  mounted() {},

  methods: {
    async handleSubmit() {
      const checkUniqueSpotType = await this.checkSpotTypeUniqueInformation();
      if (checkUniqueSpotType) {
        await this.sendInformation();
      } else {
        this.showSpotTypeCheckModal = true;
      }
    },

    async sendInformation() {
      let spotTypeData = cloneDeep(this.spotType);
      this.$emit("spotTypeSubmitted", spotTypeData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.spotType?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: this.spotType?.name,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "",
        };

        await this.$store.dispatch("spotTypes/list", params);
        this.spotTypes = this.$store.getters["spotTypes/list"];
        this.total = this.$store.getters["spotTypes/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkSpotTypeUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.spotType?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleSpotTypeCheckModalState();
      await this.sendInformation();
    },

    async toggleSpotTypeCheckModalState() {
      this.showSpotTypeCheckModal = !this.showSpotTypeCheckModal;
    },

    onCloseSpotTypeModal() {
      this.$emit("onCloseSpotTypeModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    spotTypeData(spotTypeData) {
      if (spotTypeData) {
        this.spotType = {
          ...this.spotType,
          ...cloneDeep(spotTypeData),
        };
      }
    },
  },
};
</script>
